<template>
  <App
    title="Prospect"
    @click-left="onNavBarClickLeft"
    left-arrow
    :showFooterTabBar="true"
    activeName="businessOpportunities"
  >
    <DropdownMenu
      slot="extra"
      active-color="#DA3738"
      class="dropdown-menu"
      :close-on-click-outside="calendarClosed"
    >
      <DropdownItem
        v-for="e in tabData"
        :key="e.key"
        v-model="e.value"
        :options="e.list"
        :ref="e.key"
        @change="handlechange(e.value, e.list, e.key)"
        @open="handleOpen(e.list)"
      >
        <div slot="title" v-if="e.key === 'sort'">General</div>
        <div slot="title" v-if="e.key === 'state'">Status</div>
        <div slot="title" v-if="e.key === 'closeDate'">Close Date</div>
        <div slot="title" v-if="e.key === 'tags'">客户标签</div>
        <div v-if="e.key === 'tags'" class="tags-filter">
          <div class="tags-group" v-for="group in e.cuList" :key="group.key">
            <div class="title">{{ group.title }}</div>
            <div class="tags">
              <button
                type="button"
                @click="changeTag(group.key, tag.key, group.type, group.title)"
                v-for="tag in group.children"
                :key="tag.key"
                :class="tag.active ? 'active' : ''"
              >
                {{ tag.name }}
              </button>
            </div>
          </div>
          <div class="filter-operation">
            <button type="button" class="reset" @click="resetTags">
              清空条件
            </button>
            <button type="button" class="confirm" @click="confirmTags">
              确定
            </button>
          </div>
        </div>
        <div v-if="e.key === 'closeDate'" class="close-date">
          <CellGroup style="height: 150px">
            <Field
              v-model="e.value"
              label="Date"
              readonly
              clickable
              @click="
                () => {
                  calendarShow = true;
                  calendarClosed = false;
                }
              "
              input-align="right"
              placeholder="Please select"
              right-icon="arrow"
            />
          </CellGroup>
          <div class="filter-operation">
            <button type="button" class="reset" @click="resetCloseDate">
              Clear
            </button>
            <button type="button" class="confirm" @click="confirmCloseDate">
              Filter
            </button>
          </div>
        </div>
      </DropdownItem>
    </DropdownMenu>
    <PullRefresh @refresh="onRefresh" v-model="loading">
      <List
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        :finished-text="total === 0 ? 'No data!' : 'No more items.!'"
        error-text="Loading failed. Click to retry"
        @load="OnLoad"
      >
        <CardItem v-for="$items in list" :key="$items.id" :cardInfo="$items" />
      </List>
    </PullRefresh>
    <Calendar
      ref="calendar"
      v-model="calendarShow"
      type="range"
      @confirm="calendarConfirm"
      :show-confirm="false"
      color="#DA3738"
      :default-date="calendarDefault"
      :min-date="minCloseDate"
      :max-date="maxCloseDate"
      @closed="calendarClosed = true"
    />
  </App>
</template>

<script>
import { debounce } from "lodash-es";
import CardItem from "./components/card/index";
import { oppsList, customerprofile } from "@/api/chargequery";
import {
  DropdownMenu,
  DropdownItem,
  CellGroup,
  Field,
  Calendar,
  PullRefresh,
  List,
  Toast,
} from "vant";
import "./index.less";

export default {
  name: "businessOpportunities",
  components: {
    DropdownMenu,
    DropdownItem,
    CellGroup,
    Field,
    Calendar,
    PullRefresh,
    List,
    CardItem,
  },
  data() {
    return {
      tabData: [
        {
          key: "sort",
          value: "1",
          list: [
            { text: 'Latest Follow-up Time (Ascending)', value: '1' },
            { text: 'Latest Follow-up Time (Descending)', value: '2' }
          ],
        },
        {
          key: "state",
          value: "all",
          list: [],
        },
        {
          key: "closeDate",
          value: "",
        },
        // {
        //   key: "tags",
        //   value: "",
        //   cuList: [],
        // },
      ],
      calendarShow: false,
      calendarClosed: true,
      calendarDefault: [],
      minCloseDate: new Date(2010, 0, 1),
      maxCloseDate: new Date(2050, 11, 31),
      list: [],
      // status: '',//商机状态
      startclosedate: "", //开始时间
      endclosedate: "", //结束时间
      tag: [], //客户标签
      hasDate: true,
      loading: true,
      finished: false,
      error: false,
      page: 1,
      size: 10,
      total: 0,
      timeblock: [],
      order: 1,
    };
  },
  methods: {
    onRefresh() {
      this.page = 1;
      this.finished = false;
      this.list = [];
      this.total = 0;
      this.getData();
    },
    //商机状态选择
    handlechange(val, list, key) {
      this.openKey = key;
      if (key === "state") {
        this.status = val;
      }
      if (key === "sort") {
        this.order = val;
      }
      this.onRefresh();
    },
    handleOpen(val) {
      if (val && val.length <= 0) this.initBaseData();
    },
    // 返回
    onNavBarClickLeft() {
      this.$router.go(-1);
    },
    // 客户标签点击
    changeTag(groupKey, tagKey, ctype, title) {
      this.tabData.forEach((tab) => {
        if (tab.key === "tags") {
          if (ctype === "multiple" || title === "销售二级顾虑") {
            tab.cuList.forEach((group) => {
              if (group.key === groupKey) {
                (group.children || []).forEach((tag) => {
                  if (tag.key === tagKey) {
                    tag.active = !tag.active;
                  }
                });
              }
            });
          } else {
            tab.cuList.forEach((group) => {
              if (group.key === groupKey) {
                (group.children || []).forEach((tag) => {
                  if (tag.key === tagKey) {
                    tag.active = !tag.active;
                  } else {
                    tag.active = false;
                  }
                });
              }
            });
          }
        }
      });
    },
    // 确定客户标签
    confirmTags() {
      this.add.forEach((item, index) => {
        this.add[index]["treesVal"] = this.add[index].trees.filter((el) => {
          if (el.active) {
            return el;
          }
        });
      });
      this.tag = this.filterCp();
      // 关闭DropdownItem
      this.$refs["tags"][0].toggle();
      this.onRefresh();
    },
    // 清空客户标签
    resetTags() {
      this.tabData.forEach((tab) => {
        if (tab.key === "tags") {
          tab.value = "";
          tab.cuList.forEach((group) => {
            group.selected = [];
            (group.children || []).forEach((tag) => {
              tag.active = false;
            });
          });
        }
        this.tag = [];
        this.onRefresh();
      });
      // 关闭DropdownItem
      this.$refs["tags"][0].toggle();
    },
    // Close Date日期选择
    calendarConfirm(date) {
      //转换时间戳
      this.starttime = Date.parse(new Date(date[0]));
      this.endtime = Date.parse(new Date(date[1]));
      //
      this.tabData.forEach((tab) => {
        if (tab.key === "closeDate") {
          tab.value = `${this.formatDate(date[0])} - ${this.formatDate(
            date[1]
          )}`;
        }
      });
      this.calendarShow = false;
    },
    // 用户标签处理展示
    async customerprofilefun() {
      const res = await customerprofile();
      if (res.code === "000000") {
        this.add = JSON.parse(res.data.template);
        this.add.forEach((item) => {
          item.title = item.name;
          item.key = item.code;
          item.children = item.trees;
          item.selected = [];
          item.children.forEach((el) => {
            el.key = el.code;
            el.active = false;
          });
        });
        // this.tabData[3].cuList = this.add;
      } else {
        Toast(res.msg);
      }
    },
    // 格式化日期
    formatDate(date) {
      const y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "." + m + "." + d;
    },
    // 清空 Close Date
    resetCloseDate() {
      this.tabData.forEach((tab) => {
        if (tab.key === "closeDate") {
          tab.value = "";
        }
      });
      // 关闭 DropdownItem
      this.$refs["closeDate"][0].toggle();
      // 重置 Calendar
      this.$refs["calendar"].reset();
      //清空调用
      this.starttime = "";
      this.endtime = "";
      this.getData();
    },
    // 过滤cp选择
    filterCp() {
      this.changeCpList = [];
      for (let i = 0; i < this.add.length; i++) {
        const item = this.add[i];
        if (item["treesVal"] && item["treesVal"].length > 0) {
          const _item = { ...item };
          _item["trees"] = _item["treesVal"];
          delete _item["treesVal"];
          this.changeCpList.push(_item);
        }
      }
      // 删除多余参数
      this.changeCpList.forEach((item) => {
        delete item.children;
        delete item.key;
        delete item.selected;
        delete item.active;
        delete item.title;
      });
      return JSON.stringify(this.changeCpList);
    },
    // 确认 Close Date
    confirmCloseDate() {
      // 关闭 DropdownItem
      this.$refs["closeDate"][0].toggle();
      this.onRefresh();
      // this.tag = []
    },
    OnLoad() {
      this.getData();
    },
    async init() {
      this.page = 1;
      this.list = [];
      this.total = 0;
      await this.customerprofilefun();
      await this.getData();
      await this.changeTag();
    },
    // 获取主数据
    initBaseData() {
      //取主数据
      const baseData = this.$storage.get("baseData");
      const oppstatus = baseData.opp_status;
      this.tabData[1].list = oppstatus;
      this.tabData[1].list.forEach((item) => {
        item.text = item.key;
      });
      this.tabData[1].list.unshift({ text: "All", value: "" });
    },
    // 获取数据
    getData: debounce(
      async function () {
        /* Toast.loading({
          message: "Loading...",
          forbidClick: true,
          loadingType: "spinner",
          overlay: true,
        }); */
        const params = {
          orderitem: this.order, //综合排序
          status: this.status, //商机状态
          startclosedate: this.starttime,
          endclosedate: this.endtime,
        };
        params.tag = this.tag.length ? this.tag : null;
        try {
          const res = await oppsList(
            {
              page: this.page,
              size: this.size,
            },
            params
          );
          Toast.clear();
          if (res.success) {
            this.total = res.data.total;
            this.list = [...this.list, ...res.data.records];
            if (this.list.length === res.data.total) {
              this.finished = true;
            } else {
              this.page++;
            }
            this.error = false;
            this.loading = false;
            // this.list = res.data.records
          } else {
            this.error = true;
            this.loading = false;
            Toast(res.msg);
          }
        } catch (error) {
          Toast.clear();
          this.error = true;
          this.loading = false;
        }
      },
      600,
      { leading: true, trailing: false }
    ),
  },
  mounted() {
    this.init();
  },
};
</script>